import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Data from "../../Data/Data.json";
import  {MenuImg}  from "../Images/Images";

const Menu = () => {
    const path = useLocation();
    const Navigate = useNavigate();
    const handleMenu = () =>{
        Navigate("/Menu");
    }

    const downloadPDF = () => {
        fetch('menu.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'menu.pdf';
                alink.click();
            })
        })
    }


return(
    <div className=" py-5">
    <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="section-title ff-secondary text-center text-primary fw-normal">Food Menu</h5>
            {!path.pathname.includes("Menu") ?<h1 className="mb-5">2 for £12.00</h1>:""}
        </div>
        <div className="tab-class text-center wow fadeInUp" data-wow-delay="0.1s">
            <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5">
                {/* <li className="nav-item">
                    <a className="d-flex align-items-center text-start mx-3 ms-0 pb-3 active" data-bs-toggle="pill" href="#tab-1">
                        <i className="fa fa-coffee fa-2x text-primary"></i>
                        <div className="ps-3">
                            <small className="text-body">Popular</small>
                            <h6 className="mt-n1 mb-0">Breakfast</h6>
                        </div>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="d-flex align-items-center text-start mx-3 pb-3" data-bs-toggle="pill" href="#tab-2">
                        <i className="fa fa-hamburger fa-2x text-primary"></i>
                        <div className="ps-3">
                            <small className="text-body">Special</small>
                            <h6 className="mt-n1 mb-0">Launch</h6>
                        </div>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="d-flex align-items-center text-start mx-3 me-0 pb-3" data-bs-toggle="pill" href="#tab-3">
                        <i className="fa fa-utensils fa-2x text-primary"></i>
                        <div className="ps-3">
                            <small className="text-body">Lovely</small>
                            <h6 className="mt-n1 mb-0">Dinner</h6>
                        </div>
                    </a>
                </li> */}
            </ul>
            <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                    <div className="row g-4">
                    {!path.pathname.includes("Menu") ?
                          Data.Menu.offer.map((key)=>{
                            return(<div className="col-lg-6" key={key.item}>
                            <div className="d-flex align-items-center">
                                <img className="flex-shrink-0 img-fluid rounded" src="img/menu-2.jpg" alt="" style={{width: "80px"}}/>
                                <div className="w-100 d-flex flex-column text-start ps-4">
                                    <h5 className="d-flex justify-content-between border-bottom pb-2">
                                        <span>{key?.item}</span>
                                       
                                    </h5>
                                    <small className="fst-italic">{key?.content}</small>    
                                </div>
                            </div>
                        </div>)
                          }):
                        <img src={MenuImg} alt="Menu"/>}
                        
                    </div>
                    {!path.pathname.includes("Menu") ?<button onClick={handleMenu} className="btn btn-lg mt-4  btn-primary">Full Menu <i className="fa fa-external-link-alt"></i></button> :<a href="./menu.pdf" download><button onClick={downloadPDF} className="btn btn-sm mt-4  btn-primary"><i className="fas fa-download"></i> Download Menu</button></a>}
                    
                </div>
                
            </div>
        </div>
    </div>
</div>
);
};

export default Menu;