import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import Cards from "../Common/Card";
import { Features } from "../Images/Images";
import BookDemo from "./BookDemo";

const Work = (props) => {
  const path = useLocation();
  const cls = path.pathname.includes("products") ? "pt-3" : "pt-3 nav-bg";

  const Text = () => {
    return (
      <div className='col-lg-12'>
        <h1 className="mb-4 d-flex justify-content-center d-blk">
        The Products -&nbsp;<span className={`text-primary me-2`}>{Data.Name}</span>
        </h1>
      </div>
    );
  };

  return (
    <div className={cls}>
      <div className="container ">
        <div className="row g-5 align-items-center justify-content-center">
           {Text()}
           {Data.Features.map((Service,index)=> {
            return(<Cards Heading={Service.Name} Description={Service.Description} Img={Features[index]} key={index}/>)
           })}
        </div>
      </div>
    </div>
  );
};

export default Work;
