import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { MenuImg } from "../Images/Images";

export default function Cards(Props) {
  const { Heading, Description, Img } = Props;
  return (
    <Card sx={{ maxWidth: 405, height: 800 }}>
      <CardContent sx={{ maxHeight: 325 }} className="card-m">
        <Typography
          gutterBottom
          variant="h2"
          component="div"
          className="card-main">
          {Heading}
        </Typography>
        <CardActionArea className="card-image mb-4 mt-2">
          <CardMedia
            sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
            component="img"
            image={Img}
            alt="green iguana"
          />
        </CardActionArea>
        <Typography variant="body" color="text.secondary" className="card-sub">
          {Description}
        </Typography>
      </CardContent>

      <CardActions></CardActions>
    </Card>
  );
}
